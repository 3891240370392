import { Divider, Space, Typography, message, Badge } from 'antd';
import CircleNumber from '../../atoms/CircleNumber';
import formatRupiah from '../../../helpers/rupiah-formats';
import formatMetopupBalance from '../../../helpers/metopup-balance-formats';

const PaymentMethod = ({
  paymentSection,
  paymentData,
  paymentSelected,
  setPaymentSelected,
  productSelected,
  scrollToNominal,
  scrollToCustomer,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { Title, Text } = Typography;

  const paymentFee = (productPrice = 0, feePercentage = 0, feeValue = 0) => {
    const percentFee = productPrice + (feePercentage / 100) * productPrice;
    return Math.ceil(percentFee + feeValue);
  };

  const isValidVirtualAccount = (sellingPrice, paymentMethod) => {
    if (!sellingPrice) {
      return false;
    } else if (
      sellingPrice >= paymentMethod.paymentMinimum &&
      sellingPrice <= paymentMethod.paymentMaximum
    ) {
      return true;
    }
    return false;
  };

  return (
    <div id="nominal-section" ref={paymentSection}>
      <div className="flex items-center border-primary">
        <CircleNumber value={'4'} />
        <Title className="!my-0 !ml-4 !text-lg">Pilih Metode Pembayaran</Title>
      </div>
      <Divider />
      <div>
        <Space>{contextHolder}</Space>
        {paymentData?.map((payment, index) => {
          return (
            <div key={index}>
              <div>
                <Text className="!text-lg">{payment.paymentName}</Text>
              </div>
              <div className="mb-8 mt-4 grid grid-cols-2 gap-3">
                {payment.bankList?.map((bank, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        if (!productSelected) {
                          messageApi.open({
                            type: 'error',
                            content: 'Silahkan pilih nominal dahulu',
                          });
                          scrollToNominal();
                          return;
                        }
                        if (!isValidVirtualAccount(productSelected?.sellingPrice, bank)) {
                          return;
                        }
                        setPaymentSelected(bank);
                        scrollToCustomer();
                      }}
                      className={`bg-non cursor-pointer rounded-2xl border-2 border-solid
                        border-gray-200
                        ${
                          (!productSelected?.sellingPrice ||
                            !isValidVirtualAccount(productSelected?.sellingPrice, bank)) &&
                          `!cursor-not-allowed`
                        }
                        ${
                          paymentSelected?.paymentCode === bank.paymentCode &&
                          `pointer-events-auto !border-primary`
                        }`}
                    >
                      <div className="flex flex-row items-center justify-between p-4 xs:flex-col-reverse">
                        <Text className="!text-base font-medium xs:mt-2">
                          {bank.paymentName.toUpperCase()}
                        </Text>
                        <div className="flex h-7 w-20 items-center justify-center rounded-md bg-slate-200 p-2">
                          <img
                            alt={`${bank.paymentCode}-${bank.paymentName}`}
                            src={bank.logo}
                            width={120}
                            height={120}
                            className="h-full w-auto"
                          />
                        </div>
                      </div>
                      <Divider className="!my-0" />
                      <Badge.Ribbon
                        text="Rekomendasi"
                        style={{ display: bank.paymentCode === 'WALLET_MB' ? 'block' : 'none' }}
                      >
                        <div className="flex flex-col items-center justify-center p-4">
                          {productSelected?.sellingPrice &&
                          isValidVirtualAccount(productSelected?.sellingPrice, bank) ? (
                            <div className="xs:mt-5">
                              <div className="mb-2 flex justify-center text-center">
                                <Text className="!text-center !text-xs">
                                  {bank.feePercentage > 0 || bank.feeValue > 0
                                    ? 'Biaya Layanan +'
                                    : `Biaya Layanan +${formatRupiah(0)}`}
                                  {bank.feePercentage > 0 && `${bank.feePercentage}%`}
                                  {bank.feeValue > 0 && `${formatRupiah(bank.feeValue)}`}
                                </Text>
                              </div>
                              <div className="text-center">
                                <Text className="!text-center !text-sm font-bold text-primary">
                                  {bank.paymentCode === 'WALLET_MB'
                                    ? formatMetopupBalance(
                                        paymentFee(
                                          productSelected?.sellingPrice,
                                          bank.feePercentage,
                                          bank.feeValue,
                                        ),
                                      )
                                    : formatRupiah(
                                        paymentFee(
                                          productSelected?.sellingPrice,
                                          bank.feePercentage,
                                          bank.feeValue,
                                        ),
                                      )}
                                </Text>
                              </div>
                            </div>
                          ) : (
                            <div className="flex justify-center">
                              <Text className="text-center !text-sm">
                                {!productSelected?.sellingPrice
                                  ? `Belum Pilih Nominal`
                                  : `Tidak Tersedia`}
                              </Text>
                            </div>
                          )}
                        </div>
                      </Badge.Ribbon>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Divider />
    </div>
  );
};

export default PaymentMethod;
