import CircleNumber from '../../atoms/CircleNumber';
import { Col, Row, Typography, Form, Input, Select, Divider } from 'antd';
const { Title } = Typography;

const DetailAkun = ({ games }) => {

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <div className="flex items-center border-primary">
        <CircleNumber value={'2'} />
        <Title className="!my-0 !ml-4 !text-lg">Masukkan Detail Akun</Title>
      </div>
      <Divider />
      <Row gutter={[16, 16]}>
        {games?.fieldRules?.map((field, idx) => {
          return (
            <Col key={idx} xs={24} sm={24} md={12} lg={12} xl={12}>
              {field.fieldType === 'text' && (
                <Form.Item
                  label={field.displayName}
                  name={field.keyName}
                  // initialValue={field.keyName === 'userId' ? '525231178' : '8105'}
                  rules={[
                    {
                      required: true,
                      message: `Silahkan Masukkan ${field.displayName}`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              {field.fieldType === 'options' && (
                <Form.Item
                  label={field.displayName}
                  name={field.keyName}
                  rules={[
                    {
                      required: true,
                      message: `Silahkan Masukkan ${field.displayName}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={field.options}
                    filterOption={filterOption}
                  />
                </Form.Item>
              )}
            </Col>
          );
        })}
      </Row>
      <Divider />
    </div>
  );
};

export default DetailAkun;
