import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Select,
  Form,
  Button,
  Typography,
  Divider,
  notification,
  Space,
  Row,
  Col,
  Input,
  InputNumber,
  Table,
} from 'antd';
import { privateApiInstance } from '../../helpers/api';
import { useParams, useNavigate } from 'react-router-dom';
import formatRupiah from '../../helpers/rupiah-formats';
import { roundToNearestThousand, roundToNearestTen } from '../../helpers/price';

const { Title } = Typography;

function GamesForm() {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const fileReader = new FileReader();

  const [messageApi, contextHolder] = notification.useNotification();
  const [optionsProductGroup, setOptionsProductGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sellingChannel, setSellingChannel] = useState('');
  const [data, setData] = useState([]);
  const [productList, setProductList] = useState([]);

  const [form] = Form.useForm();

  const initColumn = (optionGroup) => {
    return [
      {
        title: 'Product Group ID',
        dataIndex: 'productGroupId',
        align: 'center',
        key: 'skuCode',
        render: (value, record, index) => {
          return (
            <Select
              className="w-52"
              showSearch
              initialValue={value}
              optionFilterProp="children"
              options={optionGroup}
              onChange={(value) => {
                const populateData = [...productList];
                populateData[index].productGroupId = value;
                setProductList(populateData);
              }}
            />
          );
        },
      },
      {
        title: 'Sequence',
        dataIndex: 'sequence',
        align: 'center',
        key: 'skuCode',
        render: (value, record, index) => {
          return (
            <InputNumber
              defaultValue={value}
              onChange={(value) => {
                const populateData = [...productList];
                populateData[index].sequence = value;
                setProductList(populateData);
              }}
            />
          );
        },
      },
      {
        title: 'Partner Price',
        dataIndex: 'partnerPrice',
        key: 'skuCode',
        align: 'center',
        render: (value, record, index) => {
          return formatRupiah(value);
        },
      },
      {
        title: 'Markup Percentage',
        dataIndex: 'markupPercentage',
        key: 'skuCode',
        align: 'center',
        render: (value, record, index) => {
          return (
            <InputNumber
              defaultValue={value}
              onChange={(value) => {
                const populateData = [...productList];
                populateData[index].markupPercentage = value;
                const newPrice =
                  parseInt(record.partnerPrice) + record.partnerPrice * (value / 100);
                populateData[index].price =
                  sellingChannel === 'web'
                    ? roundToNearestThousand(newPrice)
                    : roundToNearestTen(newPrice);
                setProductList(populateData);
              }}
            />
          );
        },
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'skuCode',
        align: 'center',
        render: (value, record, index) => {
          return formatRupiah(productList[index].price);
        },
      },
      {
        title: 'SKU Code ',
        dataIndex: 'skuCode',
        key: 'skuCode',
      },
      {
        title: 'Product Name',
        dataIndex: 'productName',
        align: 'center',
        key: 'skuCode',
      },
      {
        title: 'Seller Name',
        dataIndex: 'sellerName',
        align: 'center',
        key: 'skuCode',
      },
    ];
  };

  const getProductGroup = useCallback(() => {
    privateApiInstance
      .get(`admin/product-group/${gameId}`)
      .then(({ data: response }) => {
        const dropdownProductGroup = response.data.map((game) => {
          return {
            value: game.productGroupId,
            label: game.name,
          };
        });
        setOptionsProductGroup(dropdownProductGroup);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [gameId]);

  useEffect(() => {
    getProductGroup();
  }, [getProductGroup]);

  const onFinish = (values) => {
    console.log(values);
    setIsLoading(true);

    privateApiInstance
      .post(`/admin/product/bulk-insert`, {
        ...values,
        gameId: parseInt(gameId),
        productList,
      })
      .then(() => {
        messageApi.success({
          type: 'success',
          message: `Berhasil Bulk Upload Product`,
          onClose: () => navigate('/dashboard/master/games', { replace: true }),
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const csvToArr = (stringValue) => {
    const formattedString = stringValue.trim().split('\n');
    formattedString.splice(0, 1);
    const populateData = formattedString
      .map((dt, idx) => {
        const data = dt.split(';');
        return {
          skuCode: data[1],
          productName: data[2],
          sellerName: data[3],
          partnerPrice: data[4],
          sequence: idx + 1,
          productGroupId: '',
          logoUrl: '',
          markupPercentage: 0,
          price: 0,
        };
      })
      .sort((a, b) => {
        // Extract the numeric parts from the strings
        let numA = parseInt(a.skuCode.match(/\d+$/)?.[0], 10);
        let numB = parseInt(b.skuCode.match(/\d+$/)?.[0], 10);

        // Compare the numeric parts
        return numA - numB;
      });
    return populateData.map((data, idx) => {
      return { ...data, sequence: idx + 1 };
    });
  };

  const handleOnChange = (e) => {
    if (e.target.files[0]) {
      fileReader.readAsText(e.target.files[0]);
      fileReader.onload = (event) => {
        setData(csvToArr(event.target.result));
        setProductList(csvToArr(event.target.result));
      };
    }
  };

  return (
    <>
      <Space>{contextHolder}</Space>

      <div className="flex items-center border-primary">
        <Title className="!my-0 !ml-4 !text-lg">Bulk Upload Product</Title>
      </div>
      <Divider />

      <Form
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 8 }}
              labelAlign="left"
              label="Order Rules"
              name="orderRules"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: `Silahkan Masukkan Order Rules`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 8 }}
              labelAlign="left"
              label="Product Name Replace"
              name="productNameReplace"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: `Silahkan Masukkan Product Name Replace`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 8 }}
              labelAlign="left"
              name="sellingChannel"
              label="Selling Channel"
              initialValue=""
              rules={[{ required: true, message: 'Please input Selling Channel!' }]}
            >
              <Select
                showSearch
                placeholder="Pilih Selling Channel"
                optionFilterProp="children"
                onChange={(value) => {
                  setSellingChannel(value);
                }}
                options={[
                  {
                    value: 'web',
                    label: 'web',
                  },
                  {
                    value: 'reseller',
                    label: 'reseller',
                  },
                  {
                    value: 'itemku',
                    label: 'itemku',
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 8 }}
              labelAlign="left"
              name="category"
              label="Partner Category"
              initialValue=""
              rules={[{ required: true, message: 'Please input Partner Category!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 8 }}
              labelAlign="left"
              name="channel"
              label="Channel"
              initialValue="Digiflazz"
              rules={[{ required: true, message: 'Please input Channel!' }]}
            >
              <Select
                showSearch
                placeholder="Pilih Channel"
                optionFilterProp="children"
                options={[
                  {
                    value: 'Digiflazz',
                    label: 'Digiflazz',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-8">
          <Col span={24}>
            <input
              className="!hidden"
              type={'file'}
              accept={'.csv'}
              ref={inputFileRef}
              onChange={handleOnChange}
            />
            <Button
              type="primary"
              disabled={!sellingChannel}
              onClick={() => {
                inputFileRef.current.click();
              }}
            >
              Upload CSV
            </Button>
          </Col>
        </Row>

        <Row>
          <Table
            virtual
            columns={initColumn(optionsProductGroup)}
            // pagination={pagination}
            pagination={false}
            dataSource={data}
            loading={isLoading}
            scroll={{ x: 1500 }}
            // onChange={handleTableChange}
          />
        </Row>

        <div className="mt-8 flex items-end">
          <Button
            className="!bg-primary hover:!bg-[#3c89e8]"
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
          >
            Simpan
          </Button>
        </div>
      </Form>
    </>
  );
}

export default GamesForm;
