import { useState, useEffect, useCallback } from 'react';
import { Typography, Divider, Space, Table, Row, Col, Input, Button, Image, Switch } from 'antd';
import { privateApiInstance } from '../../helpers/api';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, CloudUploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
const { Title } = Typography;
const { Search } = Input;

function Games() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomRight'],
    total: 0,
  });

  const columns = [
    {
      title: 'Nama Game',
      dataIndex: 'gameName',
      key: 'gameId',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      align: 'center',
      key: 'gameId',
    },
    {
      title: 'Sequence',
      dataIndex: 'sequence',
      align: 'center',
      key: 'gameId',
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'gameId',
      align: 'center',
      render: (value, record) => {
        return (
          <Switch
            loading={isLoading}
            checked={value}
            onChange={(value) => {
              changeActiveGames(value, record.gameId);
            }}
          />
        );
      },
    },
    {
      title: 'Cover',
      dataIndex: 'logoUrl',
      align: 'center',
      key: 'gameId',
      render: (value, row) => {
        return (
          <Image height={100} width={100} src={value} alt={row.gameName} className="rounded-md" />
        );
      },
    },
    {
      title: 'Action',
      align: 'center',
      key: 'gameId',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => navigate(`form/${record.gameId}`)}
          >
            Edit
          </Button>

          <Button
            icon={<CloudUploadOutlined />}
            onClick={() => navigate(`form/${record.gameId}/bulk-insert`)}
          >
            Bulk Insert Product
          </Button>
        </Space>
      ),
    },
  ];

  const getGamesData = useCallback(
    (search = '') => {
      setIsLoading(true);
      privateApiInstance
        .get(`admin/games?limit=${pagination.pageSize}&page=${pagination.current}&search=${search}`)
        .then(({ data: response }) => {
          setData(response.data);
          setPagination({
            ...pagination,
            current: response?.page,
            pageSize: response?.limit,
            total: response?.totalData,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.pageSize, pagination.current],
  );

  const changeActiveGames = useCallback(
    (value, gameId) => {
      setIsLoading(true);
      privateApiInstance
        .put(`admin/games/${gameId}/set-active`, {
          isActive: value,
        })
        .then(() => {
          getGamesData(search);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [getGamesData, search],
  );

  useEffect(() => {
    getGamesData(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGamesData]);

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination });
  };

  return (
    <>
      <div className="flex items-center border-primary">
        <Title className="!my-0 !ml-4 !text-lg">Games List</Title>
      </div>
      <Divider />

      <Row className="my-8">
        <Col md={8} sm={8} xs={24}>
          <Search
            placeholder="Nama"
            defaultValue={setSearch}
            allowClear
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
        <Col className="ml-4 xs:ml-0 xs:mt-4" md={4} sm={4} xs={24}>
          <Button
            type="primary"
            onClick={() => {
              setPagination({
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                total: 0,
              });
              getGamesData(search);
            }}
          >
            Cari
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="mb-4" md={24} sm={24} xs={24}>
          <Button
            type="primary"
            onClick={() => {
              navigate('form');
            }}
          >
            Tambah Game
          </Button>
        </Col>
      </Row>
      <Table
        virtual
        columns={columns}
        pagination={pagination}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 800 }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Games;
