import { theme } from 'antd';

const defaultTheme = {
  algorithm: theme.defaultAlgorithm,
  components: {
    // Layout: {
    //   siderBg: '#0E0031',
    //   bodyBg: '#d6e2ea',
    //   padding: 0,
    // },
  },
};

export default defaultTheme;
