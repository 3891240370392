import formatRupiah from '../../../helpers/rupiah-formats';
import { Divider, Typography } from 'antd';

const { Title, Text } = Typography;

const NominalProduct = ({
  nominalSection,
  games,
  productGroup,
  productSelected,
  setProductSelected,
  scrollToSection,
}) => {
  return (
    <div id="nominal-section" ref={nominalSection}>
      <div className="flex items-center border-primary">
        <Title className="!my-0 !ml-4 !text-lg">Pilih Nominal</Title>
      </div>
      <Divider />
      <div>
        {productGroup?.map((group, index) => {
          return (
            <div key={index}>
              <div>
                <Text className="!text-lg">{group.name}</Text>
              </div>
              <div className="mb-8 mt-4 grid grid-cols-3 gap-3 xs:grid-cols-2 xs:gap-3">
                {group.products?.map((product, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        setProductSelected(product);
                        scrollToSection();
                      }}
                      className={`cursor-pointer rounded-2xl border-2 border-solid border-gray-200 ${
                        productSelected?.productId === product?.productId &&
                        '!border-gray-500 bg-slate-300'
                      }`}
                    >
                      <div className="flex flex-col items-center justify-center p-4">
                        <img
                          alt={`${games?.gameName}-${product?.productName}`}
                          src={product?.logoUrl}
                          width={200}
                          height={200}
                          className="h-10 w-10"
                        />
                        <Text
                          className={`mt-2 text-center !text-base ${
                            productSelected?.productId === product?.productId && '!text-primary'
                          }`}
                        >
                          {product.productName}
                        </Text>
                        <Text
                          className={`mt-2 text-center !text-xs ${
                            productSelected?.productId === product?.productId && '!text-primary'
                          }`}
                        >
                          {formatRupiah(product.sellingPrice)}
                        </Text>
                        <Text
                          className={`mt-2 text-center !text-xs ${
                            productSelected?.productId === product?.productId && '!text-primary'
                          }`}
                        >
                          Partner Price: {formatRupiah(product?.partner?.[0]?.price)}
                        </Text>
                        <Text
                          className={`mt-2 text-center !text-xs ${
                            productSelected?.productId === product.productId && '!text-primary'
                          }`}
                        >
                          Partner Name: {product?.partner?.[0]?.sellerName}
                        </Text>
                        <Text
                          className={`mt-2 text-center !text-xs ${
                            productSelected?.productId === product.productId && '!text-primary'
                          }`}
                        >
                          SKU Code: {product?.partner?.[0]?.skuCode}
                        </Text>
                        <Text
                          className={`mt-2 text-center !text-xs ${
                            productSelected?.productId === product.productId && '!text-primary'
                          }`}
                        >
                          Seller Status: {product?.partner?.[0]?.sellerStatus ? 'Active' : 'InActive'}
                        </Text>
                        <Text
                          className={`mt-2 text-center !text-xs ${
                            productSelected?.productId === product.productId && '!text-primary'
                          }`}
                        >
                          Buyer Status {product?.partner?.[0]?.buyerStatus ? 'Active' : 'InActive'}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NominalProduct;
