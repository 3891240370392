import { Typography } from 'antd';
const { Text } = Typography;

const CircleNumber = ({ value }) => {
  return (
    <div className="bg-primary flex h-8 w-8 items-center justify-center rounded-full">
      <Text className="!text-lg !text-white">{value}</Text>
    </div>
  );
};

export default CircleNumber;
