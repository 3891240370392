import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Select,
  Form,
  Button,
  Typography,
  Divider,
  notification,
  Space,
  Modal,
  Row,
  Col,
} from 'antd';
import CircleNumber from '../../components/atoms/CircleNumber';
import { privateApiInstance } from '../../helpers/api';
import DetailAkun from '../../components/molecules/DetailAkun';
import NominalProductReseller from '../../components/molecules/NominalProductReseller';
import PaymentMethod from '../../components/molecules/PaymentMethod';
import { ShoppingCartOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

function CreateOrderReseller() {
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const [games, setGames] = useState([]);
  const [messageApi, contextHolder] = notification.useNotification();
  const [optionsGames, setOptionsGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState();
  const [productGroup, setProductGroup] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [productSelected, setProductSelected] = useState(null);
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nickName, setNickName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const nominalSection = useRef(null);
  const paymentSection = useRef(null);
  const createOrderSection = useRef(null);
  const [userDetails, setUserDetails] = useState({});
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const scrollToSection = (ref) => {
    if (ref.current) {
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = ref.current.getBoundingClientRect().top;
      const offset = elementRect - bodyRect;
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };

  const getGamesData = useCallback(() => {
    privateApiInstance
      .get(`admin/games?limit=150&page=1`)
      .then(({ data: response }) => {
        setGames(response.data);
        const dropdownGames = response.data.map((game) => {
          return {
            value: game.gameId,
            label: game.gameName,
          };
        });
        setOptionsGames(dropdownGames);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getPaymentMethod = useCallback(() => {
    privateApiInstance
      .get(`admin/payment`)
      .then(({ data: response }) => {
        setPaymentMethod(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getProductData = useCallback((gameId) => {
    privateApiInstance
      .get(`admin/product/${gameId}`)
      .then(({ data: response }) => {
        setProductGroup(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const checkUID = useCallback(
    (payload) => {
      privateApiInstance
        .post(`admin/order/check-uid`, {
          ...payload,
        })
        .then(({ data: response }) => {
          if (!response.username) {
            messageApi.error({
              type: 'error',
              description: 'Mohon periksa kembali Detail Akun!',
              message: 'User ID Salah!',
            });
            return;
          }
          setNickName(response.username);
          showModal();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [messageApi],
  );

  const createTrxOrder = useCallback(() => {
    const payload = {
      userDetails,
      productId: productSelected?.productId,
      paymentMethodId: paymentSelected?.paymentMethodId,
    };
    privateApiInstance
      .post(`/admin/order/reseller`, {
        ...payload,
      })
      .then(({ data: response }) => {
        messageApi.success({
          type: 'success',
          description: `Invoice No: ${response?.data?.invNumber}`,
          message: 'Sukses membuat order',
        });
        navigate(`/reseller/order/${response?.data?.invNumber}`);
      })
      .catch((err) => {
        console.log(err);
        messageApi.error({
          type: 'error',
          description: 'Terjadi Kesalahan',
          message: err,
        });
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsLoading(false);
      });
  }, [
    messageApi,
    navigate,
    paymentSelected?.paymentMethodId,
    productSelected?.productId,
    userDetails,
  ]);

  useEffect(() => {
    getGamesData();
    getPaymentMethod();
  }, [getGamesData, getPaymentMethod]);

  useEffect(() => {
    if (selectedGame) {
      getProductData(selectedGame?.gameId);
    }
  }, [getProductData, selectedGame]);

  const onChangeGame = (value) => {
    const gameData = games.find((game) => game.gameId === value);
    setSelectedGame(gameData);
  };

  const onFinish = (values) => {
    if (!productSelected) {
      messageApi.error({
        type: 'error',
        description: 'Silahkan pilih nominal dahulu',
        message: 'Terjadi kesalahan',
      });
      scrollToSection(nominalSection);
      return;
    }

    if (!paymentSelected) {
      messageApi.error({
        type: 'error',
        description: 'Silahkan pilih metode pembayaran dahulu',
        message: 'Terjadi kesalahan',
      });
      scrollToSection(paymentSection);
      return;
    }

    const userDetailsData = {
      ...values,
    };
    delete userDetailsData.gameId;
    delete userDetailsData.sellingChannel;
    setUserDetails({ ...userDetailsData });
    if (selectedGame.isCheckUID) {
      setIsLoading(true);
      checkUID({ userDetails: userDetailsData, gameId: values.gameId });
      return;
    }
    showModal();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsLoading(true);
    createTrxOrder();
  };

  const convertCamelCaseToReadable = (input) => {
    return input
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between camel case
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Insert space between camel case and digits
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Insert space between consecutive uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  return (
    <>
      <Space>{contextHolder}</Space>

      <div className="flex items-center border-primary">
        <CircleNumber value={'1'} />
        <Title className="!my-0 !ml-4 !text-lg">Buat Pesanan</Title>
      </div>
      <Divider />

      <Form
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        scrollToFirstError={true}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name="gameId"
              label="Kategori"
              initialValue=""
              rules={[{ required: true, message: 'Silahkan masukkan kategori!' }]}
            >
              <Select
                showSearch
                placeholder="Pilih Kategori"
                optionFilterProp="children"
                onChange={onChangeGame}
                filterOption={filterOption}
                options={optionsGames}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        <DetailAkun games={selectedGame} />
        <NominalProductReseller
          nominalSection={nominalSection}
          games={games}
          productGroup={productGroup}
          productSelected={productSelected}
          setProductSelected={setProductSelected}
          scrollToSection={() => scrollToSection(paymentSection)}
        />
        <PaymentMethod
          paymentSection={paymentSection}
          paymentData={paymentMethod}
          paymentSelected={paymentSelected}
          setPaymentSelected={setPaymentSelected}
          productSelected={productSelected}
          scrollToNominal={() => scrollToSection(nominalSection)}
          scrollToCustomer={() => scrollToSection(createOrderSection)}
        />
        <div className="flex items-end" id="create-order-section" ref={createOrderSection}>
          <Button
            className="!bg-primary hover:!bg-[#3c89e8]"
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
            icon={<ShoppingCartOutlined />}
          >
            Beli Sekarang
          </Button>
        </div>
      </Form>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Batalkan
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
            Pesan Sekarang
          </Button>,
        ]}
      >
        <div className="flex items-center justify-center">
          <Title className="!my-0 mr-4" level={3}>
            Konfirmasi Pesanan
          </Title>
          <CheckCircleFilled style={{ color: '#52C41A', fontSize: '20px' }} />
        </div>
        <div className="mb-6 mt-2 flex items-center justify-center">
          <Text>Pastikan Pesananmu sudah benar</Text>
        </div>
        <div className="mb-8">
          <div className="p-4">
            <div className="grid grid-cols-2 gap-3">
              {nickName && (
                <>
                  <Text>Username</Text>
                  <Text>{nickName}</Text>
                </>
              )}

              {userDetails &&
                Object.keys(userDetails).map((kd, idx) => {
                  return (
                    <>
                      <Text key={`${idx}-kd`}>{convertCamelCaseToReadable(kd)}</Text>
                      <Text key={idx}>{userDetails[kd]}</Text>
                    </>
                  );
                })}

              <Text>Produk</Text>
              <Text>{selectedGame?.gameName}</Text>

              <Text>Item</Text>
              <Text>{productSelected?.productName}</Text>

              <Text>Metode Pembayaran</Text>
              <Text>{paymentSelected?.paymentName}</Text>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateOrderReseller;
