import { useState, useEffect } from 'react';
import {
  FormOutlined,
  SnippetsOutlined,
  LogoutOutlined,
  ContainerOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoaderData } from 'react-router-dom';
import formatMetopupBalance from '../../../helpers/metopup-balance-formats';

const { Header, Content, Footer, Sider } = Layout;

const BaseLayout = ({ children, logout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useLoaderData();

  const { Title } = Typography;

  const [selectedKey, setSelectedKey] = useState('/');
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    if (profile.role === 'admin' || profile.role === 'ultraman') {
      setMenuData([
        {
          key: '/dashboard/master-data',
          label: 'Master Data',
          icon: <DatabaseOutlined />,
          children: [
            {
              key: '/dashboard/master/games',
              label: 'Games',
            },
          ],
        },
        {
          key: '/dashboard/create-order',
          label: 'Create Order',
          icon: <FormOutlined />,
        },
        {
          key: '/dashboard/order',
          label: 'Order',
          icon: <SnippetsOutlined />,
        },
        {
          key: '/logout',
          label: 'Logout',
          icon: <LogoutOutlined />,
        },
      ]);
      return;
    }
    if (profile.role === 'reseller') {
      setMenuData([
        {
          key: '/reseller',
          label: 'Order',
          icon: <ContainerOutlined />,
          children: [
            {
              key: '/reseller/create-order',
              label: 'Create Order',
              icon: <FormOutlined />,
            },
            {
              key: '/reseller/order',
              label: 'Order',
              icon: <SnippetsOutlined />,
            },
          ],
        },
        {
          key: '/logout',
          label: 'Logout',
          icon: <LogoutOutlined />,
        },
      ]);
      return;
    }
  }, [profile.role]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    menuData.forEach((m) => {
      if (location.pathname.includes(m.key)) {
        setSelectedKey(m.key);
      }
    });
  }, [location.pathname, menuData]);

  return (
    <Layout className="!min-h-screen">
      <Sider breakpoint="lg" collapsedWidth="0">
        <div className="h-16" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          items={menuData}
          onClick={({ key }) => {
            if (key === '/logout') {
              logout();
              return navigate('/', { replace: true });
            }
            navigate(key);
          }}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="ml-4">
            <Title level={4}>Metopup Balance: {formatMetopupBalance(profile.balance)}</Title>
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Metopup copyright ©2023</Footer>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
