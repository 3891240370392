import { Tag } from 'antd';

const FullFillmentState = ({ fullFillmentState }) => {
  switch (fullFillmentState) {
    case 'WAITING':
      return <Tag color="warning">Pending</Tag>;
    case 'REQUESTED':
      return <Tag color="cyan">Requested</Tag>;
    case 'IN_PROGRESS':
      return <Tag color="cyan">In Progress</Tag>;
    case 'COMPLETE':
      return <Tag color="success">Complete</Tag>;
    case 'FAILED':
      return <Tag color="error">Failed</Tag>;
    default:
      return <Tag color="error">Error</Tag>;
  }
};
export default FullFillmentState;
