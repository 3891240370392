import React, { useState } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import cookies from '../../helpers/cookies';
import axios from 'axios';
import PropTypes from 'prop-types';

const { REACT_APP_API_BASE_URL } = process.env;

export default function Uploader(props) {
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    const isValidFormat =
      props.imageValidation?.length > 0 ? props.imageValidation.includes(file.type) : true;
    if (!isValidFormat) {
      const message = props.imageValidation.map((format) => format.split('/')[1]);
      props.messageApi.error({
        type: 'error',
        description: `Format gambar harus ${message.join(', ')}`,
        message: 'Upload Error',
      });
    }
    const isValidSize = file.size / 1024 / 1024 < props.fileSizeMB;
    if (!isValidSize) {
      props.messageApi.error({
        type: 'error',
        description: `Gambar harus lebih kecil dari ${props.fileSizeMB}MB!`,
        message: 'Upload Error',
      });
    }
    return isValidFormat && isValidSize;
  };

  const retryUploadFile = async (file, token) => {
    const formData = new FormData();
    formData.append('image', file);
    return await axios.post(`${REACT_APP_API_BASE_URL}/${props.path}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const uploadFoto = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      props.onChange(info.file.response.url);
      setLoading(false);
    }
    if (info.file.status === 'error') {
      if (info.file?.error?.status === 401) {
        const refreshToken = cookies.get('refreshToken');
        const responseRefreshToken = await axios
          .post(`${REACT_APP_API_BASE_URL}/admin/auth/refresh-token`, { refreshToken })
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return err;
          });
        if (responseRefreshToken.status === 200) {
          // when refresh token success set cookies and retry using axios
          cookies.set('accessToken', responseRefreshToken.data.accessToken, { path: '/' });
          cookies.set('refreshToken', responseRefreshToken.data.refreshToken, { path: '/' });
          const result = await retryUploadFile(
            info.file?.originFileObj,
            responseRefreshToken.data.accessToken,
          );
          if (result.status === 201) {
            props.onChange(result.data.fileName);
            setLoading(false);
            return;
          }
        }
      }
      props.messageApi.error({
        type: 'error',
        description: info.file.response.message,
        message: 'Upload Error',
      });
      setLoading(false);
    }
  };
  const token = cookies.get('accessToken');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return (
    <ImgCrop
      rotationSlider
      showGrid
      aspect={props.aspect}
      minZoom={1}
      quality={1}
      modalTitle="Crop Image"
      modalOk="Upload"
      modalCancel="Batal"
    >
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={`${REACT_APP_API_BASE_URL}/${props.path}`}
        headers={headers}
        beforeUpload={beforeUpload}
        onChange={uploadFoto}
      >
        {props.value ? (
          <img src={props.value} height={100} width={100} alt={props.value} />
        ) : (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>
    </ImgCrop>
  );
}

Uploader.propTypes = {
  aspect: PropTypes.number.isRequired,
  imageValidation: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileSizeMB: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  path: PropTypes.string.isRequired,
  messageApi: PropTypes.any.isRequired,
};
