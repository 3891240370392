import formatRupiah from '../../../helpers/rupiah-formats';
import CircleNumber from '../../atoms/CircleNumber';
import { Divider, Typography } from 'antd';

const { Title, Text } = Typography;

const NominalProductReseller = ({
  nominalSection,
  games,
  productGroup,
  productSelected,
  setProductSelected,
  scrollToSection,
}) => {
  return (
    <div id="nominal-section" ref={nominalSection}>
      <div className="flex items-center border-primary">
        <CircleNumber value={'3'} />
        <Title className="!my-0 !ml-4 !text-lg">Pilih Nominal</Title>
      </div>
      <Divider />
      <div>
        {productGroup?.map((group, index) => {
          return (
            <div key={index}>
              <div>
                <Text className="!text-lg">{group.name}</Text>
              </div>
              <div className="mb-8 mt-4 grid grid-cols-4 gap-3 xs:grid-cols-2 xs:gap-3">
                {group.products?.map((product, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        setProductSelected(product);
                        scrollToSection();
                      }}
                      className={`cursor-pointer rounded-2xl border-2 border-solid border-gray-200 ${
                        productSelected?.productId === product?.productId &&
                        '!border-primary bg-slate-300'
                      }`}
                    >
                      <div className="flex flex-col items-center justify-center p-4">
                        {product?.logoUrl && (
                          <img
                            alt={`${games?.gameName}-${product?.productName}`}
                            src={product?.logoUrl}
                            width={200}
                            height={200}
                            className="h-10 w-10"
                          />
                        )}
                        <Text
                          className={`mt-2 text-center !text-lg ${
                            productSelected?.productId === product?.productId && '!text-primary'
                          }`}
                        >
                          {product.productName}
                        </Text>
                        <Text
                          className={`mt-6 text-center !text-base !font-bold ${
                            productSelected?.productId === product?.productId && '!text-primary'
                          }`}
                        >
                          {formatRupiah(product.sellingPrice)}
                        </Text>
                        {product?.marketPrice && (
                          <>
                            <Text
                              className={`mt-1 text-center !text-base ${
                                productSelected?.productId === product?.productId && '!text-primary'
                              }`}
                            >
                              Rekomendasi harga jual:
                            </Text>
                            <Text
                              className={`mt-1 text-center !text-base ${
                                productSelected?.productId === product?.productId && '!text-primary'
                              }`}
                            >
                              <span className="!font-bold">
                                {formatRupiah(product?.marketPrice)}
                              </span>
                            </Text>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Divider />
    </div>
  );
};

export default NominalProductReseller;
