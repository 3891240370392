import React, { useState } from 'react';
import { Layout, Form, Button, Input, notification } from 'antd';
import { publicApiInstance } from '../../helpers/api';
import cookies from '../../helpers/cookies';
import { useNavigate } from 'react-router-dom';

function Signin() {
  const [isLoading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const openErrorNotification = (description) => {
    api.error({
      message: 'Invalid Login',
      description: description,
    });
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { data: res } = await publicApiInstance.post('/admin/auth/login', {
        email: values.email,
        password: values.password,
      });
      if (!res.data) {
        openErrorNotification('Wrong email and password');
        return;
      }

      cookies.set('accessToken', res.data.accessToken, { path: '/' });
      cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
      cookies.set('profile', btoa(JSON.stringify({ ...res.data.user, balance: 0 })), {
        path: '/',
      });

      if (res.data.user.role === 'admin' || res.data.user.role === 'ultraman') {
        navigate('/dashboard/create-order', { replace: true });
        return;
      }
      if (res.data.user.role === 'reseller') {
        navigate('/reseller/create-order', { replace: true });
        return;
      }
    } catch (err) {
      openErrorNotification('Upps.. Something went wrong');
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="flex !min-h-screen items-center justify-center">
      {contextHolder}
      <div>
        <div className="w-96 rounded bg-white px-6 pb-2 pt-10">
          <Form
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              initialValue=""
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email address!' },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              initialValue=""
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item style={{ marginTop: '56px' }}>
              <Button loading={isLoading} type="primary" block htmlType="submit">
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Layout>
  );
}

export default Signin;
