import { useState, useEffect, useCallback } from 'react';
import {
  Select,
  Form,
  Button,
  Typography,
  Divider,
  notification,
  Space,
  Row,
  Col,
  Input,
  InputNumber,
  Switch,
} from 'antd';
import { privateApiInstance } from '../../helpers/api';
import { useParams, useNavigate } from 'react-router-dom';
import Uploader from './Uploader';
const { Title } = Typography;

function GamesForm() {
  const { TextArea } = Input;
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = notification.useNotification();
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const getCategoryData = useCallback(() => {
    privateApiInstance
      .get(`admin/categories?limit=100&page=1`)
      .then(({ data: response }) => {
        const dropdownGames = response.categories.map((game) => {
          return {
            value: game.categoryId,
            label: game.categoryName,
          };
        });
        setOptionsCategory(dropdownGames);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getGameDetail = useCallback(() => {
    setIsLoading(true);
    privateApiInstance
      .get(`admin/games/${gameId}`)
      .then(({ data: response }) => {
        const { data } = response;
        form.setFieldValue('categoryId', data.categoryId);
        form.setFieldValue('gameName', data.gameName);
        form.setFieldValue('slug', data.slug);
        form.setFieldValue('sequence', data.sequence);
        form.setFieldValue('variant', data.variant);
        form.setFieldValue('discountPercentage', data.discountPercentage);
        form.setFieldValue('fieldRules', JSON.stringify(data.fieldRules));
        form.setFieldValue('howTo', data.howTo);
        form.setFieldValue('cutOffStartTime', data.cutOffStartTime);
        form.setFieldValue('cutOffEndTime', data.cutOffEndTime);
        form.setFieldValue('logoUrl', data.logoUrl);
        form.setFieldValue('isMaintenance', data.isMaintenance);
        form.setFieldValue('isCheckUID', data.isCheckUID);
        form.setFieldValue('checkUIDProvider', data.checkUIDProvider);
        form.setFieldValue('bodyRequest', JSON.stringify(data.bodyRequest));
        form.setFieldValue('responsePath', data.responsePath);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [form, gameId]);

  useEffect(() => {
    getCategoryData();
    if (gameId) {
      getGameDetail();
    }
  }, [getCategoryData, gameId, getGameDetail]);

  const onFinish = (values) => {
    setIsLoading(true);
    if (gameId) {
      privateApiInstance
        .put(`/admin/games/${gameId}`, {
          ...values,
        })
        .then(() => {
          messageApi.success({
            type: 'success',
            message: `Games ${values.gameName} berhasil di update`,
            onClose: () => navigate('/dashboard/master/games', { replace: true }),
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      privateApiInstance
        .post(`/admin/games`, {
          ...values,
        })
        .then(() => {
          messageApi.success({
            type: 'success',
            message: `Games ${values.gameName} berhasil di buat`,
            onClose: () => navigate('/dashboard/master/games', { replace: true }),
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Space>{contextHolder}</Space>

      <div className="flex items-center border-primary">
        <Title className="!my-0 !ml-4 !text-lg">Game Form</Title>
      </div>
      <Divider />

      <Form
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              name="categoryId"
              label="Kategori"
              initialValue=""
              rules={[{ required: true, message: 'Please input Kategori!' }]}
            >
              <Select
                showSearch
                placeholder="Pilih Category"
                optionFilterProp="children"
                options={optionsCategory}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Nama"
              name="gameName"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: `Silahkan Masukkan Nama`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Slug"
              name="slug"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: `Silahkan Masukkan Slug`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Sequence"
              name="sequence"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: `Silahkan Masukkan Sequence`,
                },
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              name="variant"
              label="Variant"
              initialValue=""
              rules={[{ required: true, message: 'Please input Variant!' }]}
            >
              <Select
                showSearch
                placeholder="Pilih Variant"
                optionFilterProp="children"
                options={[
                  {
                    value: 'VOUCHER',
                    label: 'VOUCHER',
                  },
                  {
                    value: 'DIGITAL',
                    label: 'DIGITAL',
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Discount Percent"
              name="discountPercentage"
              initialValue={0}
              rules={[]}
            >
              <InputNumber min={0} addonAfter="%" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Field Rules"
              name="fieldRules"
              initialValue=""
              rules={[{ required: true, message: 'Please input Field Rules!' }]}
            >
              <TextArea rows={6} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="How To"
              name="howTo"
              initialValue=""
              rules={[{ required: true, message: 'Please input howTo!' }]}
            >
              <TextArea rows={6} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Cut Off Start Time"
              name="cutOffStartTime"
              initialValue=""
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Cut Off End Time"
              name="cutOffEndTime"
              initialValue=""
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Logo"
              name="logoUrl"
              initialValue={''}
              rules={[
                {
                  required: true,
                  message: `Silahkan Masukkan Logo`,
                },
              ]}
            >
              <Uploader
                aspect={1}
                imageValidation={['image/jpeg', 'image/jpg', 'image/png', 'image/webp']}
                fileSizeMB={2}
                messageApi={messageApi}
                path="admin/games/upload"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Maintenance"
              name="isMaintenance"
              initialValue={false}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <div className="mt-4 flex items-center border-primary">
          <Title className="!my-0 !ml-4 !text-lg">Check UID</Title>
        </div>
        <Divider />
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Check UID"
              name="isCheckUID"
              initialValue={false}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Provider"
              name="checkUIDProvider"
              initialValue=""
            >
              <Select
                showSearch
                placeholder="Pilih Provider"
                optionFilterProp="children"
                options={[
                  {
                    value: 'DIGIFLAZZ',
                    label: 'DIGIFLAZZ',
                  },
                  {
                    value: 'CODASHOP',
                    label: 'CODASHOP',
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Body Request"
              name="bodyRequest"
              initialValue=""
            >
              <TextArea rows={6} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              label="Response Path"
              name="responsePath"
              initialValue=""
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex items-end">
          <Button
            className="!bg-primary hover:!bg-[#3c89e8]"
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
          >
            Simpan
          </Button>
        </div>
      </Form>
    </>
  );
}

export default GamesForm;
