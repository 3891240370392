import axios from 'axios';
import cookies from './cookies';
import { clearToken } from './utility';

const { REACT_APP_API_BASE_URL } = process.env;

const publicApiInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

publicApiInstance.interceptors.request.use((config) => {
  return config;
});

publicApiInstance.interceptors.response.use((response) => {
  return response;
});

const privateApiInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

privateApiInstance.interceptors.request.use((config) => {
  const token = cookies.get('accessToken');
  config.headers['Authorization'] = 'Bearer ' + token;
  return config;
});

privateApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (res) => {
    const { config, response } = res;
    const originalRequest = config;
    if (response && response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = cookies.get('refreshToken');
      const responseRefreshToken = await axios
        .post(`${REACT_APP_API_BASE_URL}/admin/auth/refresh-token`, { refreshToken })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
      if (responseRefreshToken.status === 200) {
        cookies.set('accessToken', responseRefreshToken.data.data.accessToken, { path: '/' });
        cookies.set('refreshToken', responseRefreshToken.data.data.refreshToken, { path: '/' });
        privateApiInstance.defaults.headers.common['Authorization'] =
          `Bearer ${responseRefreshToken.data.accessToken}`;
        return privateApiInstance(originalRequest);
      } else {
        clearToken();
        return setTimeout(function () {
          window.location.reload();
        }, 2000);
      }
    }
    return Promise.reject(response?.data?.message || res?.message);
  },
);

export { publicApiInstance, privateApiInstance };
