import Signin from './pages/auth/Signin';
import CreateOrder from './pages/order/CreateOrder';
import IndexGames from './pages/games/Index';
import GamesForm from './pages/games/Form';
import FormBulkInsert from './pages/games/Form-Bulk-Insert';
import CreateOrderReseller from './pages/order/CreateOrderReseller';
import Order from './pages/order/Order';
import OrderDetail from './pages/order/OrderDetail';
import BaseLayout from './components/organisms/BaseLayout';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import cookies from './helpers/cookies';
import { privateApiInstance } from './helpers/api';

const AppRouter = () => {
  const logout = () => {
    cookies.remove('accessToken', { path: '/' });
    cookies.remove('refreshToken', { path: '/' });
    cookies.remove('profile', { path: '/' });
    return redirect('/');
  };

  const getProfile = () => {
    const userProfile = cookies.get('profile');
    if (!userProfile) {
      return;
    }

    const profile = atob(userProfile);
    return JSON.parse(profile);
  };

  const getUserProfileBalance = async () => {
    const profileData = await getProfile();
    const walletBalance = await privateApiInstance
      .get(`admin/wallet`)
      .then(({ data }) => {
        return data?.data?.balance || 0;
      })
      .catch(() => {
        return 0;
      });
    const profile = { ...profileData, balance: walletBalance };
    return profile;
  };

  const routerList = [
    {
      path: '/',
      element: <Signin />,
    },
    {
      path: 'reseller',
      children: [
        {
          path: 'create-order',
          element: (
            <BaseLayout logout={logout}>
              <CreateOrderReseller />
            </BaseLayout>
          ),
          loader: getUserProfileBalance,
        },
        {
          path: 'order',
          element: (
            <BaseLayout logout={logout}>
              <Order />
            </BaseLayout>
          ),
          loader: getUserProfileBalance,
        },
        {
          path: 'order/:invNo',
          element: (
            <BaseLayout logout={logout}>
              <OrderDetail />
            </BaseLayout>
          ),
          loader: getUserProfileBalance,
        },
      ],
      loader: () => {
        const accessToken = cookies.get('accessToken');
        const profile = getProfile();
        if (!accessToken || !profile) {
          return logout();
        }
        return profile;
      },
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'master/games',
          element: (
            <BaseLayout logout={logout}>
              <IndexGames />
            </BaseLayout>
          ),
          loader: getProfile,
        },
        {
          path: 'master/games/form',
          element: (
            <BaseLayout logout={logout}>
              <GamesForm />
            </BaseLayout>
          ),
          loader: getProfile,
        },
        {
          path: 'master/games/form/:gameId',
          element: (
            <BaseLayout logout={logout}>
              <GamesForm />
            </BaseLayout>
          ),
          loader: getProfile,
        },
        {
          path: 'master/games/form/:gameId/bulk-insert',
          element: (
            <BaseLayout logout={logout}>
              <FormBulkInsert />
            </BaseLayout>
          ),
          loader: getProfile,
        },
        {
          path: 'create-order',
          element: (
            <BaseLayout logout={logout}>
              <CreateOrder />
            </BaseLayout>
          ),
          loader: getProfile,
        },
        {
          path: 'order',
          element: (
            <BaseLayout logout={logout}>
              <Order />
            </BaseLayout>
          ),
          loader: getProfile,
        },
        {
          path: 'order/:invNo',
          element: (
            <BaseLayout logout={logout}>
              <OrderDetail />
            </BaseLayout>
          ),
          loader: getProfile,
        },
      ],
      loader: () => {
        const accessToken = cookies.get('accessToken');
        const profile = getProfile();
        const isAdmin = profile?.role !== 'admin' || profile?.role !== 'ultraman';
        if (!accessToken || !profile || !isAdmin) {
          return logout();
        }
        return '';
      },
    },
  ];

  const router = createBrowserRouter(routerList);

  return <RouterProvider router={router} />;
};

export default AppRouter;
