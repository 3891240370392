import { useState, useEffect, useCallback } from 'react';
import { Typography, Divider, Space, Table, Row, Col, Input, DatePicker, Button } from 'antd';
import { privateApiInstance } from '../../helpers/api';
import OrderState from '../../components/atoms/OrderState';
import FullFillmentState from '../../components/atoms/FullFillmentState';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import formatRupiah from '../../helpers/rupiah-formats';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
const { Title } = Typography;
const { Search } = Input;

function Order() {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [invNumber, setInvNumber] = useState('');
  const [selectedDate, setSelectedDate] = useState([dayjs(), dayjs()]);
  const [isLoading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomRight'],
    total: 0,
  });

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invNumber',
      key: 'invNumber',
    },
    {
      title: 'Selling Channel',
      dataIndex: 'sellingChannel',
      align: 'center',
      key: 'sellingChannel',
    },
    {
      title: 'Payment Price',
      dataIndex: 'paymentPrice',
      align: 'center',
      key: 'paymentPrice',
      render: (value) => {
        return formatRupiah(value || '-');
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      render: (value) => {
        return dayjs(value).format('DD/MM/YYYY HH:mm');
      },
    },
    {
      title: 'Order Status',
      dataIndex: 'orderState',
      key: 'orderState',
      align: 'center',
      render: (value) => {
        return <OrderState orderState={value} />;
      },
    },
    {
      title: 'Full Fillment Status',
      dataIndex: 'fullFilmentStatus',
      align: 'center',
      key: 'fullFilmentStatus',
      render: (value) => {
        return <FullFillmentState fullFillmentState={value} />;
      },
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`${record.invNumber}`}>Detail</Link>
        </Space>
      ),
    },
  ];

  const getOrderData = useCallback(
    (invNumber, selectedDate) => {
      setIsLoading(true);
      privateApiInstance
        .get(
          `admin/order?limit=${pagination.pageSize}&page=${
            pagination.current
          }&invNumber=${invNumber}&startDate=${selectedDate[0].utc().format(
            'YYYY-MM-DD',
          )}&endDate=${selectedDate[1].utc().format('YYYY-MM-DD')}`,
        )
        .then(({ data: response }) => {
          setData(response.data);
          setPagination({
            ...pagination,
            current: response?.page,
            pageSize: response?.limit,
            total: response?.totalData,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.pageSize, pagination.current],
  );

  useEffect(() => {
    getOrderData(invNumber, selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderData]);

  const handleTableChange = (pagination) => {
    setPagination({ ...pagination });
  };

  return (
    <>
      <div className="flex items-center border-primary">
        <Title className="!my-0 !ml-4 !text-lg">Order List</Title>
      </div>
      <Divider />

      <Row className="my-8">
        <Col md={8} sm={8} xs={24}>
          <Search
            placeholder="Invoice No"
            defaultValue={invNumber}
            allowClear
            onChange={(e) => {
              setInvNumber(e.target.value);
            }}
          />
        </Col>
        <Col className="ml-4 xs:ml-0 xs:mt-4" md={4} sm={4} xs={24}>
          <RangePicker
            defaultValue={selectedDate}
            format={'DD-MM-YYYY'}
            allowClear={false}
            onChange={(value) => {
              setSelectedDate(value);
            }}
          />
        </Col>
        <Col className="ml-4 xs:ml-0 xs:mt-4" md={4} sm={4} xs={24}>
          <Button
            type="primary"
            onClick={() => {
              setPagination({
                current: 1,
                pageSize: 10,
                position: ['bottomRight'],
                total: 0,
              });
              getOrderData(invNumber, selectedDate);
            }}
          >
            Cari
          </Button>
        </Col>
      </Row>
      <Table
        virtual
        columns={columns}
        pagination={pagination}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 800, y: 1200 }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Order;
