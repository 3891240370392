import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';

const OrderState = ({ orderState }) => {
  switch (orderState) {
    case 'ORDER_STATE_WAITING_PAYMENT':
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          Unpaid
        </Tag>
      );
    case 'ORDER_STATE_COMPLETE':
    case 'ORDER_STATE_PAID':
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Paid
        </Tag>
      );
    case 'ORDER_STATE_CANCELLED':
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Cancelled
        </Tag>
      );
    case 'ORDER_STATE_REFUNDED':
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Refunded
        </Tag>
      );
    default:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Unknown
        </Tag>
      );
  }
};
export default OrderState;
