import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Typography,
  Divider,
  Table,
  Row,
  Col,
  QRCode,
  Button,
  Space,
  notification,
  Popconfirm,
} from 'antd';
import { privateApiInstance } from '../../helpers/api';
import { useParams } from 'react-router-dom';
import OrderState from '../../components/atoms/OrderState';
import FullFillmentState from '../../components/atoms/FullFillmentState';
import BalanceState from '../../components/atoms/BalanceState';
import dayjs from 'dayjs';
import formatRupiah from '../../helpers/rupiah-formats';
import { useLoaderData } from 'react-router-dom';

const { Title, Text } = Typography;

function OrderDetail() {
  const { invNo } = useParams();
  const [data, setData] = useState();
  const [messageApi, contextHolder] = notification.useNotification();
  const [columns, setColumns] = useState([
    {
      title: 'Customer No',
      dataIndex: 'customerNo',
      key: 'customerNo',
      width: 150,
    },
    {
      title: 'SKU',
      dataIndex: 'skuCode',
      key: 'skuCode',
      align: 'center',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      width: 150,
      render: (value) => {
        return dayjs(value).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
      render: (value) => {
        return value || '-';
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: 150,
      render: (value) => {
        return value || '-';
      },
    },
  ]);
  const profile = useLoaderData();
  const [isAdmin] = useState(profile.role === 'admin' || profile.role === 'ultraman');

  const convertCamelCaseToReadable = (input) => {
    return input
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between camel case
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Insert space between camel case and digits
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Insert space between consecutive uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  const columnsBalance = [
    {
      title: 'Metopup Balance',
      dataIndex: 'balance',
      key: 'balance',
      width: 150,
      render: (value) => {
        return formatRupiah(value || '-');
      },
    },
    {
      title: 'Status',
      dataIndex: 'mutationState',
      key: 'mutationState',
      width: 150,
      render: (value) => {
        return <BalanceState mutationState={value} />;
      },
    },
  ];

  useEffect(() => {
    if (isAdmin) {
      setColumns([
        {
          title: 'Customer No',
          dataIndex: 'customerNo',
          key: 'customerNo',
          width: 150,
        },
        {
          title: 'SKU',
          dataIndex: 'skuCode',
          key: 'skuCode',
          align: 'center',
          width: 150,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          width: 150,
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          width: 150,
          render: (value) => {
            return formatRupiah(value || '-');
          },
        },
        {
          title: 'Last Saldo',
          dataIndex: 'buyerLastSaldo',
          key: 'buyerLastSaldo',
          align: 'center',
          width: 150,
          render: (value) => {
            return formatRupiah(value || '-');
          },
        },
        {
          title: 'Date',
          dataIndex: 'createdAt',
          align: 'center',
          key: 'createdAt',
          width: 150,
          render: (value) => {
            return dayjs(value).format('DD/MM/YYYY HH:mm:ss');
          },
        },
        {
          title: 'Contact',
          align: 'center',
          dataIndex: 'wa',
          key: 'wa',
          width: 150,
          render: (_, record) => {
            return `${record.tele} - ${record.wa}`;
          },
        },
        {
          title: 'SN',
          dataIndex: 'sn',
          key: 'sn',
          render: (value) => {
            return value || '-';
          },
        },
        {
          title: 'RC',
          dataIndex: 'rc',
          key: 'rc',
          align: 'center',
          width: 150,
          render: (value) => {
            return value || '-';
          },
        },
        {
          title: 'SN',
          dataIndex: 'sn',
          key: 'sn',
          align: 'center',
          width: 150,
          render: (value) => {
            return value || '-';
          },
        },
        {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
          width: 150,
          render: (value) => {
            return value || '-';
          },
        },
      ]);
    }
  }, [isAdmin]);

  const getOrderDetailData = useCallback(() => {
    privateApiInstance
      .get(`admin/order/${invNo}`)
      .then(({ data: response }) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrderDetailData();
  }, [getOrderDetailData]);

  const downloadQRCode = () => {
    const canvas = document.getElementById('paymentQR')?.querySelector('canvas');
    if (canvas) {
      const newCanvas = document.createElement('canvas');
      const newContext = newCanvas.getContext('2d');
      // Set the new canvas dimensions
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height;

      // Draw a white background
      newContext.fillStyle = 'white';
      newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);

      // Draw the QR code on top of the white background
      newContext.drawImage(canvas, 0, 0);
      newCanvas.toBlob(
        (blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `metopup-qrcode-${dayjs().valueOf()}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        'image/png',
        1,
      );
    }
  };

  const retryTransaction = useCallback(() => {
    privateApiInstance
      .post(`admin/order/retryFullfillment`, {
        orderId: data.orderId,
      })
      .then(() => {
        messageApi.success({
          type: 'success',
          description: 'Request Retry Berhasil',
        });
        getOrderDetailData();
      })
      .catch((err) => {
        console.log(err);
        messageApi.error({
          type: 'error',
          description: 'Request Retry Gagal',
          message: 'Silahkah coba kembali atau hubungi admin!',
        });
      });
  }, [data?.orderId, getOrderDetailData, messageApi]);

  const sortedUserDetails = useMemo(() => {
    return [...Object.entries(data?.userDetails || [])].sort((a, b) => {
      const keyA = a[0].toLowerCase();
      const keyB = b[0].toLowerCase();

      if (keyA === 'nickname') return -1;
      if (keyB === 'nickname') return 1;
      return 0;
    });
  }, [data?.userDetails]);

  return (
    <>
      <Space>{contextHolder}</Space>
      <div className="flex items-center border-primary">
        <Title className="!my-0 !text-lg">Order Detail {invNo}</Title>
      </div>
      <Divider />
      <Row className="my-8">
        <Col md={8} sm={8} xs={24}>
          <div className="flex items-center border-primary">
            <Title level={4} className="!my-0 !text-base">
              Transaksi Status
            </Title>
          </div>
          <Divider />
          <div className="grid grid-cols-2 gap-4">
            <Text>Invoice No:</Text>
            <Text>{data?.invNumber}</Text>

            <Text>Order Status:</Text>
            <div>
              <OrderState orderState={data?.orderState} />
            </div>

            <Text>Full Fillment Status:</Text>
            <div className="flex items-center justify-between">
              <FullFillmentState fullFillmentState={data?.fullFilmentStatus} />
              {data?.orderState === 'ORDER_STATE_PAID' && data?.fullFilmentStatus === 'FAILED' && (
                <Popconfirm
                  title="Konfirmasi Retry?"
                  description="Apakah anda yakin mencoba kembali untuk fullfillment?"
                  onConfirm={() => {
                    retryTransaction();
                  }}
                  okText="Ya"
                  cancelText="Batal"
                >
                  <Button size="small" className="!bg-primary hover:!bg-[#3c89e8]" type="primary">
                    Retry
                  </Button>
                </Popconfirm>
              )}
            </div>

            <Text>Total Pembayaran:</Text>
            <Text>{formatRupiah(data?.paymentPrice || '-')}</Text>

            {isAdmin && (
              <>
                <Text>Harga Partner:</Text>
                <Text>{formatRupiah(data?.partnerPrice || '-')}</Text>
              </>
            )}

            <Text>Selling Channel:</Text>
            <Text>{data?.sellingChannel}</Text>

            <Text>Tanggal Trx:</Text>
            <Text>{dayjs(data?.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
          </div>
        </Col>
        <Col md={1} sm={1} xs={24} />
        <Col md={15} sm={15} xs={24}>
          <div className="flex items-center border-primary xs:mt-12">
            <Title level={4} className="!my-0 !text-base">
              Full Fillment Mutation
            </Title>
          </div>
          <Divider />
          <Table
            columns={columns}
            scroll={{ x: 800 }}
            dataSource={data?.fullFillmentHistories}
            pagination={{ pageSize: 2 }}
          />
        </Col>
      </Row>

      <Row className="my-8">
        <Col md={8} sm={8} xs={24}>
          <div className="flex items-center border-primary">
            <Title level={4} className="!my-0 !text-base">
              User Detail
            </Title>
          </div>
          <Divider />
          <div className="grid grid-cols-2 gap-4">
            {sortedUserDetails?.map(([key, value]) => {
              // skip zoneId value when options field
              if (Object.keys(data?.userDetails).includes(`${key}Label`)) {
                return null;
              }
              return (
                <>
                  <Text>{convertCamelCaseToReadable(key.replaceAll('Label', ''))}</Text>
                  <Text>{decodeURIComponent(value)}</Text>
                </>
              );
            })}
            {data?.nickName && (
              <>
                {' '}
                <Text>Nickname:</Text>
                <Text>{data?.nickName || '-'}</Text>
              </>
            )}

            <Text>Category Name:</Text>
            <Text>{data?.orderItems?.gameName}</Text>
            <Text>Product Name:</Text>
            <Text>{data?.orderItems?.productName}</Text>
            <Text>Product Price:</Text>
            <Text>{formatRupiah(data?.orderItems?.productPrice || '-')}</Text>
          </div>
        </Col>
        <Col md={1} sm={1} xs={24} />
        <Col md={15} sm={15} xs={24}>
          {data?.paymentMethod === 'PG' && (
            <div className="xs:mt-8">
              <div className="flex items-center border-primary">
                <Title level={4} className="!my-0 !text-base">
                  Payment Detail
                </Title>
              </div>
              <Divider />
              <div className="grid grid-cols-2 gap-4">
                <Text>Payment Method:</Text>
                <Text>{data?.orderPaymentDetail?.paymentMethod?.paymentName || '-'}</Text>

                {data?.orderPaymentDetail?.paymentMethod?.paymentGroup === 'QR' && (
                  <>
                    <div>
                      <div id="paymentQR">
                        <QRCode
                          value={data?.paymentInfo?.accountNumber}
                          style={{ marginBottom: 16 }}
                          size={280}
                        />
                      </div>
                      <div className="">
                        <Button
                          className="!bg-primary hover:!bg-[#3c89e8]"
                          type="primary"
                          onClick={downloadQRCode}
                        >
                          Download QR
                        </Button>
                      </div>
                    </div>
                    <div />
                  </>
                )}

                {data?.orderPaymentDetail?.paymentMethod?.paymentGroup === 'VIRTUAL_ACCOUNT' && (
                  <>
                    <Text>Account Number:</Text>
                    <Text>{data?.paymentInfo?.accountNumber || '-'}</Text>
                  </>
                )}

                {data?.orderPaymentDetail?.paymentMethod?.paymentGroup === 'OVER_THE_COUNTER' && (
                  <>
                    <Text>Kode Pembayaran:</Text>
                    <Text>{data?.paymentInfo?.accountNumber || '-'}</Text>
                  </>
                )}

                <Text>Account Name:</Text>
                <Text>{data?.paymentInfo?.accountName || '-'}</Text>
                {isAdmin && (
                  <>
                    <Text>Sell Fee:</Text>
                    <Text>{formatRupiah(data?.orderBill?.sellingFee || '-')}</Text>
                  </>
                )}

                <Text>Payment Expired:</Text>
                <Text>
                  {data?.paymentInfo?.paymentExpired
                    ? dayjs(data?.paymentInfo?.paymentExpired).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </Text>
              </div>
            </div>
          )}

          {data?.paymentMethod === 'MB' && (
            <>
              <div className="flex items-center border-primary xs:mt-12">
                <Title level={4} className="!my-0 !text-base">
                  Mutasi Metopup Balance
                </Title>
              </div>
              <Divider />
              <Table
                columns={columnsBalance}
                dataSource={data?.userWalletMutation}
                pagination={{ pageSize: 3 }}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default OrderDetail;
