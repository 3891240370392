import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Select,
  Form,
  Button,
  Typography,
  Divider,
  notification,
  Space,
  Modal,
  Row,
  Col,
} from 'antd';
import { privateApiInstance } from '../../helpers/api';
import DetailAkun from '../../components/molecules/DetailAkun';
import NominalProduct from '../../components/molecules/NominalProduct';
import { ShoppingCartOutlined, CheckCircleFilled } from '@ant-design/icons';
const { Title, Text } = Typography;

function CreateOrder() {
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const [games, setGames] = useState([]);
  const [messageApi, contextHolder] = notification.useNotification();
  const [optionsGames, setOptionsGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState();
  const [productGroup, setProductGroup] = useState([]);
  const [productSelected, setProductSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nickName, setNickName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const nominalSection = useRef(null);
  const [userDetails, setUserDetails] = useState({});
  const [sellingChannel, setSellingChannel] = useState('itemku');
  const [form] = Form.useForm();

  const camelCase = (str) => {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  };

  const scrollToSection = (ref) => {
    if (ref.current) {
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = ref.current.getBoundingClientRect().top;
      const offset = elementRect - bodyRect;
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };

  const getGamesData = useCallback(() => {
    privateApiInstance
      .get(`admin/games?limit=150&page=1`)
      .then(({ data: response }) => {
        setGames(response.data);
        const dropdownGames = response.data.map((game) => {
          return {
            value: game.gameId,
            label: game.gameName,
          };
        });
        setOptionsGames(dropdownGames);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getProductData = useCallback(
    (gameId) => {
      privateApiInstance
        .get(`admin/product/${gameId}?sellingChannel=${sellingChannel}`)
        .then(({ data: response }) => {
          setProductGroup(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [sellingChannel],
  );

  const checkUID = useCallback(
    (payload) => {
      privateApiInstance
        .post(`admin/order/check-uid`, {
          ...payload,
        })
        .then(({ data: response }) => {
          if (!response.username) {
            messageApi.error({
              type: 'error',
              description: 'Status Check ID Active dan UserID salah',
              message: 'User ID Salah!',
            });
            return;
          }
          setNickName(response.username);
          showModal();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [messageApi],
  );

  const createTrxOrder = useCallback(() => {
    const payload = {
      userDetails,
      sellingChannel,
      productId: productSelected?.productId,
    };
    privateApiInstance
      .post(`/admin/order`, {
        ...payload,
      })
      .then(({ data: response }) => {
        messageApi.success({
          type: 'success',
          description: `Invoice No: ${response?.data?.invNumber}`,
          message: 'Sukses membuat order',
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsLoading(false);
      });
  }, [messageApi, productSelected?.productId, sellingChannel, userDetails]);

  useEffect(() => {
    getGamesData();
  }, [getGamesData]);

  useEffect(() => {
    if (selectedGame) {
      getProductData(selectedGame?.gameId);
    }
  }, [getProductData, selectedGame]);

  const onChangeGame = (value) => {
    const gameData = games.find((game) => game.gameId === value);
    setSelectedGame(gameData);
  };

  const onFinish = (values) => {
    if (!productSelected) {
      messageApi.error({
        type: 'error',
        description: 'Silahkan pilih nominal dahulu',
        message: 'Terjadi kesalahan',
      });
      scrollToSection(nominalSection);
      return;
    }

    setIsLoading(true);
    const userDetailsData = {
      ...values,
    };
    delete userDetailsData.gameId;
    delete userDetailsData.sellingChannel;
    setUserDetails({ ...userDetailsData });
    if (selectedGame.isCheckUID) {
      checkUID({ userDetails: userDetailsData, gameId: values.gameId });
      return;
    }

    // createTrxOrder();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsLoading(true);
    createTrxOrder();
  };

  return (
    <>
      <Space>{contextHolder}</Space>

      <div className="flex items-center border-primary">
        <Title className="!my-0 !ml-4 !text-lg">Pilih Game</Title>
      </div>
      <Divider />

      <Form
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name="sellingChannel"
              label="Selling Channel"
              initialValue={sellingChannel}
              rules={[{ required: true, message: 'Please input games!' }]}
            >
              <Select
                showSearch
                placeholder="Pilih Channel"
                optionFilterProp="children"
                onChange={(value) => {
                  setSellingChannel(value);
                }}
                options={[
                  {
                    value: 'itemku',
                    label: 'itemku',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name="gameId"
              label="Game"
              initialValue=""
              rules={[{ required: true, message: 'Please input games!' }]}
            >
              <Select
                showSearch
                placeholder="Pilih Game"
                optionFilterProp="children"
                onChange={onChangeGame}
                filterOption={filterOption}
                options={optionsGames}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex items-center border-primary">
          <Title className="!my-0 !ml-4 !text-lg">
            {`Game CheckID Status: ${selectedGame?.isCheckUID ? 'Active' : 'InActive'}`}
          </Title>
        </div>
        <Divider />

        <DetailAkun games={selectedGame} />
        <NominalProduct
          nominalSection={nominalSection}
          games={games}
          productGroup={productGroup}
          productSelected={productSelected}
          setProductSelected={setProductSelected}
          scrollToSection={() => {}}
        />
        <div className="flex items-end">
          <Button
            className="!bg-primary hover:!bg-[#3c89e8]"
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
            icon={<ShoppingCartOutlined />}
          >
            Beli Sekarang
          </Button>
        </div>
      </Form>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Batalkan
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
            Pesan Sekarang
          </Button>,
        ]}
      >
        <div className="flex items-center justify-center">
          <Title className="!my-0 mr-4" level={3}>
            Konfirmasi Pesanan
          </Title>
          <CheckCircleFilled style={{ color: '#52C41A', fontSize: '20px' }} />
        </div>
        <div className="mb-6 mt-2 flex items-center justify-center">
          <Text>Pastikan Pesananmu sudah benar</Text>
        </div>
        <div className="mb-8">
          <div className="p-4">
            <div className="grid grid-cols-2 gap-3">
              {nickName && (
                <>
                  <Text>Username</Text>
                  <Text>{nickName}</Text>
                </>
              )}

              {sellingChannel && (
                <>
                  <Text>Selling Channel</Text>
                  <Text>{sellingChannel}</Text>
                </>
              )}

              {userDetails &&
                Object.keys(userDetails).map((kd, idx) => {
                  return (
                    <>
                      <Text key={`${idx}-kd`}>{camelCase(kd)}</Text>
                      <Text key={idx}>{userDetails[kd]}</Text>
                    </>
                  );
                })}

              <Text>Produk</Text>
              <Text>{selectedGame?.gameName}</Text>

              <Text>Item</Text>
              <Text>{productSelected?.productName}</Text>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateOrder;
