import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const BalanceState = ({ mutationState }) => {
  switch (mutationState) {
    case 'DEDUCTION':
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Pengurangan Saldo
        </Tag>
      );
    case 'REFUNDED':
      return (
        <Tag icon={<CloseCircleOutlined />} color="warning">
          Pengembalian Saldo
        </Tag>
      );
    default:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Unknown
        </Tag>
      );
  }
};
export default BalanceState;
